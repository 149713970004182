import * as THREE from "three";
import { EventDispatcher } from '../../application/EventDispatcher';
import { MeshFlags } from '../scene/MeshFlags';
import { WebGLRenderer } from './WebGLRenderer';

export const Events = {
  WEBGL_CONTEXT_LOST: 'webglcontextlost',
  WEBGL_CONTEXT_RESTORED: 'webglcontextrestored'
};
let BaseClass;
/// #if threejsVersion == 'R71'
{
  BaseClass = WebGLRenderer;
}
/// #else
{
  BaseClass = THREE.WebGLRenderer;
}
/// #endif


export class LMVRenderer extends BaseClass {
  #animationFrameCallbacks;
  #loadingAnimationDuration;
  #parentRender;
  #parentSetRenderTarget;
  #threeMRT;
  /// #if threejsVersion != 'R71'
  #viewportStack;
  #viewport;
  /// #endif

  constructor(params = {}) {
    super(params);

    params.canvas?.addEventListener('webglcontextlost', () => {
      this.fireEvent({ type: Events.WEBGL_CONTEXT_LOST });
    });
    params.canvas?.addEventListener('webglcontextrestored', () => {
      this.fireEvent({ type: Events.WEBGL_CONTEXT_RESTORED });
    });
    this.refCount = 0;

    this.#animationFrameCallbacks = [];
    this.#loadingAnimationDuration = -1;
    this.highResTimeStamp = -1;
    // render fuction is not part of the prototype but is assigned when instantiating the base class, that
    // is why we re-assign the render function
    this.#parentRender = this.render;
    this.#parentSetRenderTarget = this.setRenderTarget;
    this.render = LMVRenderer.prototype.render.bind(this);
    this.setRenderTarget = LMVRenderer.prototype.setRenderTarget.bind(this);

    /// #if threejsVersion != 'R71'
    this.#viewportStack = [];
    this.#viewport = new THREE.Vector4();
    this.physicallyCorrectLights = true;
    /// #endif
  }

  /**
   * @public
   * @returns {boolean} True if multiple render targets is supported in the current browser
   */
  supportsMRT() {
    return this.capabilities.isWebGL2 || this.extensions.get('WEBGL_draw_buffers');
  }

  /**
   * Note: We might think of deleting this as this seems to be a workaround for browsers that only support WebGL and
   * are different than IE11
   * @public
   * We need to use more than WebGL 1.0 technically allows -- we use
   * different bit depth sizes for the render targets, which is not
   * legal WebGL 1.0, but will work eventually and some platforms/browsers
   * already allow it. For others, we have to try, check for failure, and disable use of MRT dynamically. 
   * @return {boolean}
   */
  verifyMRTWorks(renderTargets) {
    let isMRTWorking = false;
    if (this.supportsMRT()) {
      /// #if threejsVersion == 'R71'
      {
        isMRTWorking = this.initFrameBufferMRT(renderTargets, true);
      }
      /// #else
      {
        isMRTWorking = true;
      }
      /// #endif
    }
    return isMRTWorking;
  }


  updateTimestamp(highResTimeStamp) {
    return this.highResTimeStamp = highResTimeStamp;
  }

  getLoadingAnimationDuration() {
    return this.#loadingAnimationDuration;
  }

  setLoadingAnimationDuration(duration) {
    return this.#loadingAnimationDuration = duration;
  }

  addAnimationLoop(callback) {
    this.#animationFrameCallbacks.push(callback);
    this.setAnimationLoop((time) => {
      for (let cb of this.#animationFrameCallbacks) {
        cb(time);
      }
    });
  }

  removeAnimationLoop(callback) {
    for (let i = 0; i < this.#animationFrameCallbacks.length; ++i) {
      if (this.#animationFrameCallbacks[i] === callback) {
        this.#animationFrameCallbacks.splice(i, 1);
        break;
      }
    }
    if (this.#animationFrameCallbacks.length === 0) {
      this.setAnimationLoop(null);
    }
  }

  clearBlend() {
    this.state.setBlending(THREE.NoBlending);
  }

  isWebGL2() {
    console.warn('LMVRenderer: .isWebGL2() has been deprecated. Use .capabilities.isWebGL2 instead.');
    return this.capabilities.isWebGL2;
  }

  _renderLMVRenderable(scene, camera) {
    if (scene.isScene) {
      this.#parentRender(scene, camera);
    } else {
      // RenderBatch
      // Here, we use the MESH_RENDERABLE flag to account for the check in the old WebGLRenrerer
      // https://git.autodesk.com/A360/firefly.js/blob/develop/src/wgs/render/WebGLRenderer.js#L3389
      // In the other case, the WebGLRenderer uses a simple for each
      scene.forEach((mesh) => {
        const oldMeshMaterial = mesh.material;
        if (scene.overrideMaterial) {
          mesh.material = scene.overrideMaterial;
        }
        this.#parentRender(mesh, camera);
        mesh.material = oldMeshMaterial;
      }, scene.renderImmediate ? (scene.forceVisible ? MeshFlags.MESH_VISIBLE : MeshFlags.MESH_RENDERFLAG) : undefined);
    }
  }

  /**
   * @overrride
   * @param {THREE.Scene|RenderBatch} scene
   * @param {THREE.Camera|Array<THREE.Camera>} camera
   * @param {Array<THREE.Light>} lights
   */
  render(scene, camera, lights) {
    /// #if threejsVersion == 'R71'
    {
      this.#parentRender(scene, camera, false, lights);
    }
    /// #else
    {
      this._renderLMVRenderable(scene, camera);

      if (scene.edgeMaterial) {
        const overrideMaterial = scene.overrideMaterial;
        scene.overrideMaterial = scene.edgeMaterial;
        this._renderLMVRenderable(scene, camera);
        scene.overrideMaterial = overrideMaterial;
      }
    }
    /// #endif
  }

  /**
   * @overrride
   * @param {THREE.WebGLRenderTarget|Array<THREE.WebGLRenderTarget>} renderTarget
   */
  setRenderTarget(renderTarget) {
    /// #if threejsVersion == 'R71'
    {
      this.#parentSetRenderTarget(renderTarget);
    }
    /// #else
    {

      if (Array.isArray(renderTarget) && renderTarget.length > 0) {
        let needsUpdate = false;
        if (!this.#threeMRT || this.#threeMRT.width !== renderTarget[0].width || this.#threeMRT.height !== renderTarget[0].height) {
          this.#threeMRT = new THREE.WebGLMultipleRenderTargets(renderTarget[0].width, renderTarget[0].height);
        }
        this.#threeMRT.texture.length = renderTarget.length;
        for (let i = 0; i < renderTarget.length; i++) {
          needsUpdate = needsUpdate || this.#threeMRT.texture[i] !== renderTarget[i].texture;
          this.#threeMRT.texture[i] = renderTarget[i].texture;
        }
        this.#threeMRT.depthTexture = renderTarget[0].depthTexture;
        if (needsUpdate) {
          this.properties.remove(this.#threeMRT);
        }

        this.getViewport(this.#threeMRT.viewport);
        this.#parentSetRenderTarget(this.#threeMRT);
      } else {
        if (renderTarget) {
          this.getViewport(renderTarget.viewport);
        }
        this.#parentSetRenderTarget(renderTarget);
      }
    }
    /// #endif
  }

  /// #if threejsVersion != 'R71'
    enableViewportOnOffscreenTargets() {
      console.warn('LMVRenderer: .enableViewportOnOffscreenTargets() has been deprecated. Renderer viewport is used by defaul');
    }

    /** Push current viewport to viewport stack, so that it can be recovered by popViewport later. */
    pushViewport() {
      this.getViewport(this.#viewport);
      this.#viewportStack.push(this.#viewport.x);
      this.#viewportStack.push(this.#viewport.y);
      this.#viewportStack.push(this.#viewport.z);
      this.#viewportStack.push(this.#viewport.w);
    }

    /** Recover previously pushed viewport.*/
    popViewport() {
      const index = this.#viewportStack.length - 4;
      
      this.setViewport(this.#viewportStack[index],
        this.#viewportStack[index+1],
        this.#viewportStack[index+2],
        this.#viewportStack[index+3]
      );

      this.#viewportStack.length = index;
    }

  /// #endif
}

EventDispatcher.prototype.apply(LMVRenderer.prototype);
LMVRenderer.Events = Events;