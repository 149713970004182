import * as THREE from "three";
/// #if threejsVersion == 'R71'
import leaflet_vert from './shaders/leaflet_vert.glsl';
import leaflet_frag from './shaders/leaflet_frag.glsl';
/// #else
import leaflet_vert_new from './shaders/leaflet_vert_new.glsl';
import leaflet_frag_new from './shaders/leaflet_frag_new.glsl';
/// #endif

import { ShaderChunks as chunks } from "./ShaderChunks.js";

let fragmentShader;
let vertexShader;
let newThreejsUniforms = [];
/// #if threejsVersion != 'R71'
{
    newThreejsUniforms = [
        {
            "offsetRepeat": { type: "v4", value: new THREE.Vector4(0, 0, 1, 1) },
        }
    ];

    vertexShader = leaflet_vert_new;
    fragmentShader = leaflet_frag_new;
}
/// #else
{
    vertexShader = leaflet_vert;
    fragmentShader = leaflet_frag;
}
/// #endif

export let LeafletShader = {
    uniforms: THREE.UniformsUtils.merge([
        THREE.UniformsLib["common"],
        chunks.CutPlanesUniforms,
        chunks.IdUniforms,
        chunks.ThemingUniform,
        {
            selectionColor: { type: "v4", value : new THREE.Vector4(0, 0, 1, 0) },
            viewportBounds: { type: "v4", value: new THREE.Vector4(0, 0, 1, 1) },
            modelLocalMatrix: { type: "m4", value: new THREE.Matrix4() },
        },
        ...newThreejsUniforms
    ]),
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
};
