import * as THREE from "three";
import { ShaderChunks as chunks } from './ShaderChunks';
/// #if threejsVersion == 'R71'
import phong_vert from './shaders/phong_vert.glsl';
import phong_frag from './shaders/phong_frag.glsl';
/// #else
import phong_vert_new from './shaders/phong_vert_new.glsl';
import phong_frag_new from './shaders/phong_frag_new.glsl';
/// #endif

let newThreejsUniforms = [];
let fragmentShader;
let vertexShader;
/// #if threejsVersion == 'R71'
{
    fragmentShader = phong_frag;
    vertexShader = phong_vert;
}
/// #else
{
    newThreejsUniforms = [
        THREE.UniformsLib[ "specularmap" ],
        THREE.UniformsLib[ "envmap" ],
        THREE.UniformsLib[ "bumpmap" ],
        THREE.UniformsLib[ "lightMap" ],
        {
            "viewMatrixInverse" : { type: "m4", value: new THREE.Matrix4() }
        }
    ];
    fragmentShader = phong_frag_new;
    vertexShader = phong_vert_new;
}
/// #endif

export let PhongShader = {

        uniforms: THREE.UniformsUtils.merge( [

            THREE.UniformsLib[ "common" ],
            ...newThreejsUniforms,
            THREE.UniformsLib[ "bump" ],
            THREE.UniformsLib[ "normalmap" ],
            THREE.UniformsLib[ "lights" ],
            THREE.UniformsLib[ "fog" ],
            chunks.CutPlanesUniforms,
            chunks.IdUniforms,
            chunks.ThemingUniform,
            chunks.ShadowMapUniforms,
            chunks.WideLinesUniforms,
            
            {
                "emissive" : { type: "c", value: new THREE.Color( 0x000000 ) },
                "specular" : { type: "c", value: new THREE.Color( 0x111111 ) },
                "shininess": { type: "f", value: 30 },
                "reflMipIndex" : { type: "f", value: 0 },

                "texMatrix" : { type: "m3", value: new THREE.Matrix3() },
                "texMatrixBump" : { type: "m3", value: new THREE.Matrix3() },
                "texMatrixAlpha" : { type: "m3", value: new THREE.Matrix3() },

                "irradianceMap": { type : "t", value: null },
                "exposureBias" : { type:"f", value: 1.0 },
                "envMapExposure" : { type:"f", value: 1.0 },
                "envRotationSin": {type: "f", value: 0.0},
                "envRotationCos": {type: "f", value: 1.0},
            }

        ] ),

    vertexShader: vertexShader,
    fragmentShader: fragmentShader

};

THREE.ShaderLib['firefly_phong'] = PhongShader;
